import React, { lazy, Suspense } from 'react'

export const menuFront = [
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', icon: 'fe fe-phone-call', disabled: true, },
    { url: '/web/pricing', key: 'pricing', title: 'บริการและราคา', },
    { url: '/web/whyme', key: 'whyme', title: 'ทำไมต้อง PDPA Thai', },
    { url: '/web/demo', key: 'demo', title: 'DEMO', },

    // { url: '/web/customerLeads', key: 'customerLeads', title: 'Customer Leads', },
    // { url: '/web/customer', key: 'customer', title: 'Customer', },
    // { url: '/web/supplier', key: 'supplier', title: 'Supplier', },
    // { url: '/web/employee', key: 'employee', title: 'Employee', },

    { url: 'https://www.facebook.com/upbrand.asia', key: 'facebook', title: 'facebook', icon: 'fe fe-facebook', },
    { url: 'tel:+66855096612', key: 'tel', title: 'โทรเลย', icon: 'fe fe-phone', },

    
    // { url: '/web/promotion', key: 'promotion', title: 'โปรโมชั่น', },
    // { url: '/web/productGroupList', key: 'productGroupList', title: 'สินค้า', },
    // { url: '/web/agent', key: 'agent', title: 'ร่วมเป็นตัวแทนกับเรา', },
    // { url: '/web/contact', key: 'contact', title: 'ติดต่อเรา', },
    // { url: '/web/cart', key: 'cart', title: 'Cart', icon: 'fe fe-shopping-cart', },
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', },
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', },
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', },
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', },
    // {
    //     title: 'Dashboards',
    //     key: 'dashboards',
    //     icon: 'fe fe-home',
    //     roles: ['admin'], 
    //     count: 8,
    //     children: [
    //     {
    //         title: 'Test',
    //         key: 'dashboardTest',
    //         url: '/dashboard/test',
    //     },
    //     ]
    // },
]

export const menuBackConfig = {
    menuBackground: {backgroundColor:"#222323"},
    menuColor1: {color:"#FFF"},
    menuColor2: {color:"#CCC"},
}
export const menuBack = {
    my: {
        title: '',
        roles: [ 'member', 'admin'],
        status: 'active',
        menu: [
            { sector: "sector1", title: "ส่วนตัว" },
            { icon: "fe fe-file-text", to: "../userMyProfile", title: "ข้อมูลส่วนตัว" },
            { sector: "sector2", title: "จัดการ" },
            { icon: "fe fe-chevron-right", to: "../policyList", title: "Policy" },
            { icon: "fe fe-chevron-right", to: "../memberPaymentList", title: "การชำระเงิน" },
            
        ],
    },
    dev: {
        title: 'Dev',
        roles: [ 'dev'],
        status: 'active',
        menu: [
            { icon: "fe fe-chevron-right", to: "../userList", title: "User Management" },
            { icon: "fe fe-chevron-right", to: "../goldSavingList", title: "Gold Saving" },
            { icon: "fe fe-chevron-right", to: "../testimonialList", title: "Testimonial" },
            { icon: "fe fe-chevron-right", to: "../homeSlideList", title: "Home Slide" },
            { icon: "fe fe-chevron-right", to: "../productList", title: "Product" },
            { icon: "fe fe-chevron-right", to: "../videoList", title: "Video" },
            { icon: "fe fe-chevron-right", to: "../videoGroupList", title: "Video Group" },
            { icon: "fe fe-chevron-right", to: "../promotionList", title: "Promotion" },
            { icon: "fe fe-chevron-right", to: "../promotionGroupList", title: "Promotion Group" },
            { icon: "fe fe-chevron-right", to: "../registerLeadList", title: "Customer Register" },
            { icon: "fe fe-chevron-right", to: "../registerServiceList", title: "Register Service" },
            { icon: "fe fe-chevron-right", to: "../budgetList", title: "Budget" },
            { icon: "fe fe-chevron-right", to: "../serviceTypeList", title: "Service Type" },
            { icon: "fe fe-chevron-right", to: "../projectImageList", title: "Project Image" },
        ],
    },
    admin: {
        title: '',
        roles: [ 'admin'],
        status: 'active',
        menu: [
            { icon: "fe fe-chevron-right", to: "../policyList", title: "Policy" },

            { sector: "sectorUser", title: "Management" },
            { icon: "fe fe-chevron-right", to: "../userList", title: "User Management" },
            
            { sector: "sectorRegister", title: "Registered" },
            { icon: "fe fe-chevron-right", to: "../registerLeadList", title: "Customer Registered" },

            { sector: "sectorHome", title: "Home Page Config" },
            { icon: "fe fe-chevron-right", to: "../homeSlideList", title: "Slide" },
            { icon: "fe fe-chevron-right", to: "../homeContentLeftRightList", title: "Content Left Right" },
            { icon: "fe fe-chevron-right", to: "../homeCustomerList", title: "Customer" },
            { icon: "fe fe-chevron-right", to: "../homeCustomerReviewList", title: "Customer Review" },

            { sector: "sectorWebConfig", title: "Web Config" },
            { icon: "fe fe-chevron-right", to: "../homeSlideList", title: "Home Slide" },
            { icon: "fe fe-chevron-right", to: "../productList", title: "Product" },
            { icon: "fe fe-chevron-right", to: "../productGroupList", title: "Product Group" },
            { icon: "fe fe-chevron-right", to: "../videoList", title: "Video" },
            { icon: "fe fe-chevron-right", to: "../videoGroupList", title: "Video Group" },
            { icon: "fe fe-chevron-right", to: "../promotionList", title: "Promotion" },
            { icon: "fe fe-chevron-right", to: "../promotionGroupList", title: "Promotion Group" },

        ],
    },
}

export const route = [
    
    { path: '/testList', Component: lazy(() => import('app/_custom/pages/test/list'))},
    { path: '/test/:id', Component: lazy(() => import('app/_custom/pages/test/edit'))},
  
    { path: '/testNewList', Component: lazy(() => import('app/_custom/pages/testNew/list'))},
    { path: '/testNewListEdit', Component: lazy(() => import('app/_custom/pages/testNew/listEdit'))},
    { path: '/testNew/:id', Component: lazy(() => import('app/_custom/pages/testNew/edit'))},
  
    { path: '/test3List', Component: lazy(() => import('app/_custom/pages/test3/list'))},
    { path: '/test3/:id', Component: lazy(() => import('app/_custom/pages/test3/edit'))},
    { path: '/test_normal', Component: lazy(() => import('app/_custom/pages/test_normal/list'))},
  
  
    // { path: '/contentList', Component: lazy(() => import('app/pdpathai/pages/content/list'))},
    { path: '/contentList', Component: lazy(() => import('app/pdpathai/pages/content/listCustom'))},
    // { path: '/content/:id', Component: lazy(() => import('app/pdpathai/pages/content/edit'))},
    { path: '/content/:id', Component: lazy(() => import('app/pdpathai/pages/content/editCustom'))},
  
    // { path: '/campaignList', Component: lazy(() => import('app/pdpathai/pages/campaign/list'))},
    { path: '/campaignList', Component: lazy(() => import('app/pdpathai/pages/campaign/listCustom'))},
    // { path: '/campaign/:id', Component: lazy(() => import('app/pdpathai/pages/campaign/edit'))},
    { path: '/campaign/:id', Component: lazy(() => import('app/pdpathai/pages/campaign/editCustom'))},
    { path: '/campaignView/:id', Component: lazy(() => import('app/pdpathai/pages/campaign/showCustom'))},
  
    { path: '/influencerList', Component: lazy(() => import('app/pdpathai/pages/influencer/list'))},
    { path: '/influencer/:id', Component: lazy(() => import('app/pdpathai/pages/influencer/edit'))},
  
    { path: '/customerList', Component: lazy(() => import('app/pdpathai/pages/customer/list'))},
    { path: '/customer/:id', Component: lazy(() => import('app/pdpathai/pages/customer/edit'))},
  
    { path: '/contentTypeList', Component: lazy(() => import('app/pdpathai/pages/contentType/list'))},
    { path: '/contentType/:id', Component: lazy(() => import('app/pdpathai/pages/contentType/edit'))},
  
  
    
    // Custom
    { path: '/userMyProfile', Component: lazy(() => import('app/_custom/pages/userMyProfile')), exact: true,},
  
  
    // pdpathai PROJECT ///////////////////////////////////////////////////////////////
    // my
    { path: '/myCredit', Component: lazy(() => import('app/pdpathai/pages/my/myCredit'))},
    
    { path: '/paymentList', Component: lazy(() => import('app/pdpathai/pages/payment/list'))},
    { path: '/payment/:id', Component: lazy(() => import('app/pdpathai/pages/payment/edit'))},

    { path: '/memberPaymentList', Component: lazy(() => import('app/pdpathai/pages/memberPayment/listCustom'))},
    { path: '/memberPayment/:id', Component: lazy(() => import('app/pdpathai/pages/memberPayment/editCustom'))},
  
  
    // user
    { path: '/userList', Component: lazy(() => import('app/pdpathai/pages/user/list'))},
    { path: '/user/:id', Component: lazy(() => import('app/pdpathai/pages/user/edit'))},
    // user
    { path: '/userPointList', Component: lazy(() => import('app/pdpathai/pages/userPoint/list'))},
    { path: '/userPoint/:id', Component: lazy(() => import('app/pdpathai/pages/userPoint/edit'))},
  
    // productGroup
    { path: '/productGroupList', Component: lazy(() => import('app/pdpathai/pages/productGroup/list'))},
    { path: '/productGroup/:id', Component: lazy(() => import('app/pdpathai/pages/productGroup/edit'))},
    // saleProduct
    { path: '/saleProductList/:id', Component: lazy(() => import('app/pdpathai/pages/saleProduct/list'))},
    { path: '/saleProductList',     Component: lazy(() => import('app/pdpathai/pages/saleProduct/list'))},
  
    { path: '/saleProduct/:id', Component: lazy(() => import('app/pdpathai/pages/saleProduct/edit'))},
    { path: '/saleProductView/:id', Component: lazy(() => import('app/pdpathai/pages/saleProduct/view'))},
    // saleProductSharing
    { path: '/saleProductSharingList/:transaction_id', Component: lazy(() => import('app/pdpathai/pages/saleProductSharing/list'))},
    { path: '/saleProductSharingList', Component: lazy(() => import('app/pdpathai/pages/saleProductSharing/list'))},
    { path: '/saleProductSharing/:id', Component: lazy(() => import('app/pdpathai/pages/saleProductSharing/edit'))},
    { path: '/mySaleProductSharingList', Component: lazy(() => import('app/pdpathai/pages/saleProductSharing/myList'))},
  
  
    { path: '/web/demo', Component: lazy(() => import('app/pdpathai/pages/web/demo'))},
    { path: '/web/customerLeads', Component: lazy(() => import('app/pdpathai/pages/web/customerLeads'))},
    { path: '/web/customer', Component: lazy(() => import('app/pdpathai/pages/web/customer'))},
    { path: '/web/supplier', Component: lazy(() => import('app/pdpathai/pages/web/supplier'))},
    { path: '/web/employee', Component: lazy(() => import('app/pdpathai/pages/web/employee'))},

    { path: '/web/thankYou', Component: lazy(() => import('app/pdpathai/pages/web/thankYou'))},
    { path: '/web/privacy', Component: lazy(() => import('app/pdpathai/pages/web/privacy'))},
    // home
    { path: '/web/policy/:id', Component: lazy(() => import('app/pdpathai/pages/web/policyShow'))},
    { path: '/web/video/:id', Component: lazy(() => import('app/pdpathai/pages/web/videoDetail'))},
    { path: '/web/promotion/:id', Component: lazy(() => import('app/pdpathai/pages/web/promotionDetail'))},


    { path: '/web/whyme', Component: lazy(() => import('app/pdpathai/pages/web/whyme'))},
    { path: '/web/pricing', Component: lazy(() => import('app/pdpathai/pages/web/pricing'))},
  
  
    { path: '/web/home', Component: lazy(() => import('app/pdpathai/pages/web/home'))},
    { path: '/web/homeRegister', Component: lazy(() => import('app/pdpathai/pages/web/homeRegister'))},
    { path: '/web/about', Component: lazy(() => import('app/pdpathai/pages/web/about'))},
    { path: '/web/contact', Component: lazy(() => import('app/pdpathai/pages/web/contact'))},
    { path: '/web/promotion', Component: lazy(() => import('app/pdpathai/pages/web/promotion'))},
    { path: '/web/salePromotion', Component: lazy(() => import('app/pdpathai/pages/web/salePromotion'))},
    { path: '/web/servicePromotion', Component: lazy(() => import('app/pdpathai/pages/web/servicePromotion'))},
    { path: '/web/video', Component: lazy(() => import('app/pdpathai/pages/web/video'))},
    { path: '/web/product/:id', Component: lazy(() => import('app/pdpathai/pages/web/product'))},
    { path: '/web/productList/:id', Component: lazy(() => import('app/pdpathai/pages/web/productList'))},
    { path: '/web/productGroupList', Component: lazy(() => import('app/pdpathai/pages/web/productGroupList'))},
    { path: '/web/savingGold', Component: lazy(() => import('app/pdpathai/pages/web/savingGold'))},
  
    { path: '/web/blog', Component: lazy(() => import('app/pdpathai/pages/web/blog'))},
    { path: '/web/agent', Component: lazy(() => import('app/pdpathai/pages/web/agent'))},
    { path: '/web/cart', Component: lazy(() => import('app/pdpathai/pages/web/cart'))},
    { path: '/web/checkout', Component: lazy(() => import('app/pdpathai/pages/web/checkout'))},
    { path: '/web/orderList', Component: lazy(() => import('app/pdpathai/pages/web/order'))},
    { path: '/web/order/:id', Component: lazy(() => import('app/pdpathai/pages/web/order'))},
    { path: '/web/orderPayment', Component: lazy(() => import('app/pdpathai/pages/web/orderPayment'))},
  
    
  
  
    // admin
    { path: '/policyList', Component: lazy(() => import('app/pdpathai/pages/policy/list'))},
    { path: '/policy/:id', Component: lazy(() => import('app/pdpathai/pages/policy/edit'))},

    { path: '/blogList', Component: lazy(() => import('app/pdpathai/pages/blog/list'))},
    { path: '/blog/:id', Component: lazy(() => import('app/pdpathai/pages/blog/edit'))},
  
    { path: '/blogGroupList', Component: lazy(() => import('app/pdpathai/pages/blogGroup/list'))},
    { path: '/blogGroup/:id', Component: lazy(() => import('app/pdpathai/pages/blogGroup/edit'))},
  
    // { path: '/homeSlideList', Component: lazy(() => import('app/pdpathai/pages/homeSlide/list'))},
    // { path: '/homeSlide/:id', Component: lazy(() => import('app/pdpathai/pages/homeSlide/edit'))},
  
    { path: '/productList', Component: lazy(() => import('app/pdpathai/pages/product/list'))},
    { path: '/product/:id', Component: lazy(() => import('app/pdpathai/pages/product/edit'))},
  
    { path: '/productGroupList', Component: lazy(() => import('app/pdpathai/pages/productGroup/list'))},
    { path: '/productGroup/:id', Component: lazy(() => import('app/pdpathai/pages/productGroup/edit'))},
  
    { path: '/videoList', Component: lazy(() => import('app/pdpathai/pages/video/list'))},
    { path: '/video/:id', Component: lazy(() => import('app/pdpathai/pages/video/edit'))},
  
    { path: '/videoGroupList', Component: lazy(() => import('app/pdpathai/pages/videoGroup/list'))},
    { path: '/videoGroup/:id', Component: lazy(() => import('app/pdpathai/pages/videoGroup/edit'))},
  
    { path: '/promotionList', Component: lazy(() => import('app/pdpathai/pages/promotion/list'))},
    { path: '/promotion/:id', Component: lazy(() => import('app/pdpathai/pages/promotion/edit'))},
  
    { path: '/promotionGroupList', Component: lazy(() => import('app/pdpathai/pages/promotionGroup/list'))},
    { path: '/promotionGroup/:id', Component: lazy(() => import('app/pdpathai/pages/promotionGroup/edit'))},
  
    { path: '/registerAgentList', Component: lazy(() => import('app/pdpathai/pages/registerAgent/list'))},
    { path: '/registerAgent/:id', Component: lazy(() => import('app/pdpathai/pages/registerAgent/edit'))},

    { path: '/registerLeadList', Component: lazy(() => import('app/pdpathai/pages/registerLead/list'))},
    { path: '/registerLead/:id', Component: lazy(() => import('app/pdpathai/pages/registerLead/edit'))},
  
    { path: '/registerServiceList', Component: lazy(() => import('app/pdpathai/pages/registerService/list'))},
    { path: '/registerService/:id', Component: lazy(() => import('app/pdpathai/pages/registerService/edit'))},
  
    { path: '/budgetList', Component: lazy(() => import('app/pdpathai/pages/budget/list'))},
    { path: '/budget/:id', Component: lazy(() => import('app/pdpathai/pages/budget/edit'))},
  
    { path: '/serviceTypeList', Component: lazy(() => import('app/pdpathai/pages/serviceType/list'))},
    { path: '/serviceType/:id', Component: lazy(() => import('app/pdpathai/pages/serviceType/edit'))},
  
    { path: '/projectImageList', Component: lazy(() => import('app/pdpathai/pages/projectImage/list'))},
    { path: '/projectImage/:id', Component: lazy(() => import('app/pdpathai/pages/projectImage/edit'))},
  
  
  
  
    { path: '/testimonialList', Component: lazy(() => import('app/pdpathai/pages/testimonial/list'))},
    { path: '/testimonial/:id', Component: lazy(() => import('app/pdpathai/pages/testimonial/edit'))},
  
    { path: '/goldSavingList', Component: lazy(() => import('app/pdpathai/pages/goldSaving/list'))},
    { path: '/goldSaving/:id', Component: lazy(() => import('app/pdpathai/pages/goldSaving/edit'))},
  
  
  
  
    // admin
    { path: '/blogList', Component: lazy(() => import('app/gentlestots/pages/blog/list'))},
    { path: '/blog/:id', Component: lazy(() => import('app/gentlestots/pages/blog/edit'))},
  
    { path: '/blogGroupList', Component: lazy(() => import('app/gentlestots/pages/blogGroup/list'))},
    { path: '/blogGroup/:id', Component: lazy(() => import('app/gentlestots/pages/blogGroup/edit'))},
  
    { path: '/contactRegisterList', Component: lazy(() => import('app/gentlestots/pages/contactRegister/list'))},
    { path: '/contactRegister/:id', Component: lazy(() => import('app/gentlestots/pages/contactRegister/edit'))},
  
]