const firebaseConfig = {
  apiKey: "AIzaSyA8e7qYvv0BvQDGlHyLgrA-yoq7eOP6Nyo",
  authDomain: "pdpathai.firebaseapp.com",
  projectId: "pdpathai",
  storageBucket: "pdpathai.appspot.com",
  messagingSenderId: "739615052725",
  appId: "1:739615052725:web:bd7e1eb335f133c3d67ffc",
  measurementId: "G-T182J6JKML"
};
export default firebaseConfig 
