import React from 'react'
import { Link } from 'react-router-dom'

const projectConfig = {
  website: 'pdpa.weget.co.th',
  appName: 'pdpathai',
  appFullName: 'PDPA Thai',
  appCopyright: `Copyright © 2020-${new Date().getFullYear()} PDPA`,
  companyName: 'PDPA Co.,Ltd.',
  appTitle: 'PDPA Thai',
  appDesc: 'PDPA Online Application',

  appLogo: <img alt="logo" src="app/pdpathai/logo/android-chrome-512x512.png" style={{ maxWidth: '40px' }}/>,
  appLogo2: <img alt="logo" src="app/pdpathai/logo/android-chrome-512x512.png" style={{ maxHeight: '60px' }}/>,

  appLogoWidth: <img alt="logo" src="app/pdpathai/logo/logo_width.png" style={{ height: '40px' }}/>,
  appLogo2Width: <img alt="logo" src="app/pdpathai/logo/logo_width.png" style={{ maxHeight: '60px', maxWidth: '200px', alignItems: 'center' }}/>,

  socialMedia: {
    // website: <a href="http://www.upbrand.asia" target="_blank" rel="noopener noreferrer" style={{marginLeft: "15px", color:"#000"}}><i className="fa fa-3x fa-globe"></i></a>,
    phone: <a href="tel:0971452215" target="_blank" rel="noopener noreferrer" style={{marginLeft: "15px", color:"#000"}}><i className="fa fa-3x fa-phone"></i></a>,
    line  : <a href="http://line.me/ti/p/~@upbrand.asia" target="_blank" rel="noopener noreferrer"><img src="etc/logos/social/logo_line.webp" style={{ marginLeft: "15px", marginTop:"2px"}} width="41px" alt="social" /></a>,
    facebook: <a href="https://www.facebook.com/upbrand.asia/" target="_blank" rel="noopener noreferrer" style={{marginLeft: "15px", color:"#3b5998"}}><i className="fa fa-3x fa-facebook-square"></i></a>,
    youtube: <a href="https://www.youtube.com/channel/UCr2yxbLjCKQSp5t_xHBqUbg" target="_blank" rel="noopener noreferrer" style={{marginLeft: "15px", color:"#FF0000"}}><i className="fa fa-3x fa-youtube-play"></i></a>,
    // instagram: <a href="https://www.instagram.com/gentlestots/" target="_blank" rel="noopener noreferrer" style={{marginLeft: "15px", color:"#9b6954"}}><i className="fa fa-4x fa-instagram"></i></a>,
    // lazada: <a href="https://www.lazada.co.th/shop/gentle-and-natural/?spm=a2o4m.pdp.seller.1.43d5559bxNsFZD&itemId=1317324711&channelSource=pdp" target="_blank" rel="noopener noreferrer"><img src="etc/logos/social/logo_lazada.webp" style={{marginLeft: "15px", marginTop:"-40px"}} width="62px" alt="social" /></a>,
    // shopee: <a href="https://shopee.co.th/gentles_tots?categoryId=58&itemId=6441237640" target="_blank" rel="noopener noreferrer"><img src="etc/logos/social/logo_shopee.webp" style={{marginLeft: "15px", marginTop:"-40px"}} width="62px" alt="social" /></a>,
  },

  frontOffice: 'active', // active / inActive

  facebook : {
    appId: '474812183840866', // default ball facebook dev app name = React Web Chat Integration

    // config at https://www.facebook.com/wegetcompany/settings/?tab=messaging
    // pageId: '391079311372123', // weget
    pageId: '306725926676739', // upbrand
    // pageId: '2003917883268443', // gentlestots
    
  },
  googleAnalytic : {
    // tracking_id : 'UA-120432092-1', // example
    // tracking_id : 'UA-120432092-1',
  }
}
export default projectConfig 